<template>
    <main id="liste" v-cloak>
        <!-- A changer par le nom du tiers sur lequel on effectue les opérations de compta -->
		<HeaderTab :title="$t('horse.ajout_cheval')" />

        <div v-if="booted" id="content">
            <div class="container-fluid">
                <div class="box row">
					<div class="col-12 col-lg">
						<div class="input-group input-group-horse-filter">
			                <div class="input-group-prepend">
			                	<e-select
									track-by="code"
									label="label"
									:options="all_types_search"
									:allow-empty="false"
									:show-labels="false"
									v-model="type_search"
								/>
			                </div>
			                <input type="text" class="form-control" v-model="search_value" @keydown.enter.prevent="search" required :placeholder="$t('horse.placeholder_'+type_search.code)">
			            </div>
					</div>
					<div class="mt-2 mt-lg-0 col-md-6 col-lg-auto">
						<b-button class="h-100" variant="secondary" block @click="openFilters">{{ $t('horse.add_filters') }} <font-awesome-icon :icon="['fal', 'plus-circle']" class="ml-2" /></b-button>
					</div>
					<div class="mt-2 mt-lg-0 col-md-6 col-lg-auto">
						<b-button class="h-100" variant="primary" block @click="search">{{ $t('global.rechercher') }} <font-awesome-icon v-if="searching" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'search']" class="ml-2" /></b-button>
					</div>
					<div class="mt-2 mt-lg-0 col-md-6 col-lg-auto">
						<router-link  :to="{ name: 'horseCreation', params: $route.params }" class="w-100 btn btn-primary">{{ $t('horse.manual_add') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></router-link>
					</div>
					<div class="col-12 d-flex">
						<div v-for="(filter, index) in filters_saved" :key="index">
							<b-button v-if="is_not_empty(filter)" @click="deleteFilter(index)" variant="primary" rounded pill size="sm" class="mt-1 mr-2 mb-3">
								{{ $t('horse.filter.'+index) }} : 
								<template v-if="index == 'race'">
									{{ race_formatted }}
								</template>
								<template v-else-if="index == 'sexe'">
									{{ filter.sexe_label }}
								</template>
								<template v-else-if="index == 'alive'">
									{{ $t('global.oui') }}
								</template>
								<template v-else>
									{{ filter }}
								</template>
								<font-awesome-icon :icon="['fal', 'times']" />
							</b-button>
						</div>
					</div>
					<div class="col-12">
						<a href="#" @click.prevent="showLicenceHorse">{{ $t('horse.choose_own_horse') }} <font-awesome-icon v-if="licence_horses_load" :icon="['fas', 'spinner']" pulse /> <font-awesome-icon v-else :icon="['fal', 'horse']" class="ml-2" /></a> (
						<a href="https://equideclic.atlassian.net/servicedesk/customer/portal/2/article/300024281?src=864825833" target="_blank">
							{{ $t('horse.user_help') }} <font-awesome-icon :icon="['fas', 'external-link']" />
						</a>)
					</div>
					<div v-if="code_erreur !== ''" class="col-12">
						<b-alert variant="danger" show>{{ error_message_trad }}</b-alert>
					</div>
					<LoadingSpinner v-if="processing" class="col-12"/>
					<template v-else-if="show_licence_horses">
						<div v-if="licence_horses.length > 0" class="col-12 mt-2">
							<div v-for="horse in licence_horses" :key="horse.horse_id" class="result_horse">
								<div class="row align-items-center">
									<div class="col">
										<h5 class="mb-0">{{ horse.horse_nom }}</h5>
										{{ horse.horse_race }}, {{ horse.horse_sexe }}, {{ horse.horse_robe }}, {{ horse.yearNaissance }}<br>
										{{ $t('horse.par') }} {{ horse.horse_pere }} {{ $t('horse.et') }} {{ horse.horse_mere }}<br>
									</div>
									<div class="col-auto">
										<b-button variant="secondary" block @click="addHorseLicence(horse)">{{ $t('global.ajouter') }}</b-button>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template v-else-if="type_search && type_search.code == 'ifce'">
						<div v-if="code_erreur === 'TMR' || horses.length == 20" class="col-12 mt-2">
							<b-alert variant="warning" show>{{ $t("global.message_affine") }}</b-alert>
						</div>
						<div v-if="horses.length > 0" class="col-12 mt-2">
							<div class="mt-4 mb-3">{{ horses.length }} {{ $t('table.general.resultats') }} :</div>
							<div v-for="horse in horses" :key="horse.references.idPub" class="result_horse">
								<div class="row align-items-center">
									<div class="col">
										<h5 class="mb-0">{{ horse.nom }}</h5>
										{{ horse.codeRace }}, {{ horse.codeSexe }}, {{ horse.codeRobe }}, {{ horse.yearNaissance }}<br>
										{{ $t('horse.par') }} {{ horse.genealogy.sireName }} {{ horse.genealogy.sireBreed }} {{ $t('horse.et') }} {{ horse.genealogy.damName }} {{ horse.genealogy.damBreed }}<br>
									</div>
									<div class="col-auto">
										<b-button variant="secondary" block @click="addHorseSire(horse)">{{ $t('global.ajouter') }}</b-button>
									</div>
								</div>
							</div>
						</div>
					</template>
                </div>
            </div>
        </div>

		<b-modal ref="filters" hide-footer>
            <template v-slot:modal-title>
            	{{ $t("horse.add_filters") }}
	        </template>
	        <div v-if="type_search && type_search.code == 'ifce'" class="row">
	        	<div class="col-12 mt-1">
					<input type="number" name="date_min" class="form-control" v-model="filters_ifce.date_min" :placeholder="getTrad('horse.filter.date_min')" />
				</div>
				<div class="col-12 mt-1">
					<input type="number" name="date_max" class="form-control" v-model="filters_ifce.date_max" :placeholder="getTrad('horse.filter.date_max')"/>	
				</div>
				<div class="col-12 mt-1">
					<input type="string" name="mother_name" class="form-control" v-model="filters_ifce.mother_name" :placeholder="getTrad('horse.filter.mother_name')"/>
				</div>
				<div class="col-12 mt-1">
					<input type="string" name="father_name" class="form-control" v-model="filters_ifce.father_name" :placeholder="getTrad('horse.filter.father_name')"/>
				</div>
				<div class="col-12 mt-1">
		        	<e-select
						id="race_code"
						v-model="filters_ifce.race"
						track-by="race_code"
						label="race_label"
						:placeholder="$t('horse.filter.race')"
						:options="horse_races"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
						:multiple="true"
					/>
				</div>
				<div class="col-12 mt-1">
					<e-select
						id="sexe_code"
						v-model="filters_ifce.sexe"
						track-by="sexe_code"
						label="sexe_label"
						:placeholder="$t('horse.filter.sexe')"
						:options="horse_sexes"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					/>
				</div>
				<div class="col-12 mt-1">
					<b-form-checkbox class="mr-2" v-model="filters_ifce.alive">{{ $t('horse.filter.alive') }}</b-form-checkbox>
				</div>
				<div class="col-12 mt-1 text-center">
					<b-button primary="secondary" @click="saveFilters"><font-awesome-icon :icon="['fal', 'save']" class="ml-2" /> {{ $t('global.sauvegarder_filtrer') }}</b-button>
				</div>
	        </div>
	    </b-modal>
    </main>
</template>

<script type="text/javascript">
	import Horse from "@/mixins/Horse.js"
    import UserMixin from "@/mixins/User.js"
    import _deepClone from 'lodash/cloneDeep'
    import _isEmpty from 'lodash/isEmpty'

	export default {
		name: 'AddHorse',
		mixins: [Horse, UserMixin],
		props: ['from'],
		data () {
			return {
				search_value: '',
				all_types_search: [],
				type_search: null,
				code_erreur: '',
				booted: false,
				searching: false,
				processing: false,
				horses: [],
				filters_ifce: {
					date_min: null,
					date_max: null,
					mother_name: null,
					father_name: null,
					race: [],
					sexe: null,
					alive: true
				},
				filters_saved: {},
				horse_sexes: [],
				horse_races: [],
				horse_robes: [],
				licence_horses: [],
				show_licence_horses: false,
				licence_horses_load: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
            	this.all_types_search.push({
            		code: 'ifce',
            		label: 'IFCE'
            	})
	            this.type_search = this.all_types_search[0]

				this.horse_sexes = await this.getSexes()
                this.horse_races = await this.getRaces()
				this.horse_sexes.push({
					sexe_code: 'MH',
					sexe_label: this.getTrad('horse.male_hongre')
				})
                this.horse_robes = await this.getRobes()

                const config_race = this.getConfigHorseRaces()
                if(config_race.length > 0) {
                	this.filters_ifce.race = this.horse_races.filter(race => config_race.includes(race.race_code))
                }
                const config_sexe = this.getConfigHorseSexes()
                if(config_sexe.length > 0) {
                	this.filters_ifce.sexe = this.horse_sexes.find(sex => sex.sexe_code == config_sexe[0])
                }

                const date = new Date()
                this.filters_ifce.date_min = date.getFullYear() - 25
                this.filters_ifce.date_max = date.getFullYear() - 2

				this.filters_saved = _deepClone(this.filters_ifce)

				this.booted = true
			},

			openFilters() {
				this.$refs.filters.show()
			},

			search() {
				this.searching = true
				this.horses = []
				const input = this.clean_input(this.search_value)
				this['search_'+this.type_search.code](input)
			},

			async search_ifce(input) {
				// On commence par tester les regex d'erreurs possibles
				this.code_erreur = this.checkPotentialError(input)
				if(this.code_erreur == '') {
					let horse_id = null
					let exists = false
					if(this.regex_sire.test(input)) {
						const data = await this.addHorse(input, 'sire')
						horse_id = data.horse_id
						exists = !data.new
					}
					else if(this.regex_microship.test(input)) {
						const data = await this.addHorse(input, 'microship')
						horse_id = data.horse_id
						exists = !data.new
					}
					else if(this.regex_ueln.test(input)) {
						const data = await this.addHorse(input, 'ueln')
						horse_id = data.horse_id
						exists = !data.new
					}
					else if(this.regex_name_like.test(input)) {
						let filters = _deepClone(this.filters_saved)
						if(!_isEmpty(filters)) {
							if(filters.race && (filters.race.length > 1 || filters.race.length == 0)) {
								filters.race = null
							}
							else if(filters.race && filters.race.length == 1) {
								filters.race = filters.race[0]
							}

							if(filters.sexe && filters.sexe.sexe_code == 'MH') {
								filters.sexe = null
							}
						}

						const data = await this.fetchHorseByNameIfce(input, filters)
						if(data.code == 0) {
							let horses = data.result
							if(!_isEmpty(this.filters_saved)) {
								if(this.filters_saved.alive) {
									horses = horses.filter(horse => !horse.dateMort)
								}

								if(this.filters_saved.race && this.filters_saved.race.length > 1) {
									const races_code = this.filters_saved.race.map(race => race.race_code)
									horses = horses.filter(horse => races_code.includes(horse.codeRace))
								}

								if(this.filters_saved.sexe && this.filters_saved.sexe.sexe_code == 'MH') {
									horses = horses.filter(horse => horse.codeSexe == 'M' || horse.codeSexe == 'H')
								}
							}
							this.horses = horses.map(horse => {
								let year = ''
								if(horse.dateNaissance) {
									const naissance = new Date(horse.dateNaissance)
									year = naissance.getFullYear()
								}
								let race = this.horse_races.find(race => race.race_code == horse.codeRace)
								race = race ? race.race_label : horse.codeRace

								let sexe = this.horse_sexes.find(sexe => sexe.sexe_code == horse.codeSexe)
								sexe = sexe ? sexe.sexe_label : horse.codeSexe

								let robe = this.horse_robes.find(robe => robe.robe_code == horse.codeRobe)
								robe = robe ? robe.robe_label : horse.codeRobe

								return {...horse, yearNaissance: year, codeRace: race, codeSexe: sexe, codeRobe: robe}
							})
						}
						else {
							this.code_erreur = data.code
						}
					}
					if(exists && horse_id) {
						this.infoToast("toast.horse_already_exists")
						this.$router.push({ name: 'HorseFicheInfo', params: {horse_id} })
					}
					else if(horse_id) {
						this.next(horse_id)
					}
				}
				this.searching = false
			},

			async addHorseSire(item) {
				this.processing = true
				const data = await this.addHorse(item.references.idPub, 'id_pub')
				if(data.new) {
					this.next(data.horse_id)
				}
				else {
					this.infoToast("toast.horse_already_exists")
					this.$router.push({ name: 'HorseFicheInfo', params: {horse_id: data.horse_id} })
				}
			},

			async addHorseWeatherbys(item) {
				this.processing = true
				const response = await this.addHorseWeatherbysID(item.id)

				if(response.code_retour === 0 && !this.from) {
					this.next(response.retour.horse_id)
				}
				else {
					this.failureToast(this.getTrad("horse.already_exists"), true)
				}
			},

			async next(horse_id) {
				await this.duplicateHorseLicence(horse_id)
				if(this.$route.params.from == 'contractAjout') {
					this.$router.push({ name: 'ContractList', params: { from: 'horseAjout', contract_id: this.$route.params.contract_id }})
				}
				else if(this.$route.params.from == "pax") {
					this.$router.push({ name: 'HorsePax', params: { horse_id }})
				}
				else {
					if(this.$store.state.userAccess.hasHorseAccess) {
						this.$router.push({ name: 'horseListe'})
					}
					else if(this.$store.state.userAccess.hasMareAccess) {
						this.$router.push({ name: 'mareListe'})
					}
					else if(this.$store.state.userAccess.hasEffectifAccess) {
						this.$router.push({ name: 'effectif'})
					}
					else {
						this.$router.push({ name: 'HomeMenu'})
					}
				}
			},

			openManuelCreation() {
                this.$router.push({name: 'AddManuelBeta'})
			},

			clean_input: function(input) {
				if(!input) return input
				input = input.toUpperCase()
				input = input.replace(/[\u2018\u2019]/g, "'")
				input = input.replace(new RegExp(/[èéêë]/g),"e")
				return input
			},

			saveFilters() {
				this.filters_saved = _deepClone(this.filters_ifce)
				this.$refs.filters.hide()
				this.search()
			},

			deleteFilter(index) {
				this.filters_saved[index] = null
				this.filters_ifce[index] = null
			},

			is_not_empty(filter) {
				return filter === true || typeof filter === 'number' || !_isEmpty(filter)
			},

			async showLicenceHorse() {
				this.show_licence_horses = true
				this.licence_horses_load = true

				let licence_horses = await this.getUserHorses()
				const clientarea_horses = await this.getHorses()
				const clientarea_ids = clientarea_horses.map(horse => horse.horse_sire || horse.horse_nom)

				licence_horses = licence_horses.filter(horse => !clientarea_ids.includes(horse.horse_sire))
                this.licence_horses = licence_horses.map(h => {
					let year = ''
					if(h.dateNaissance) {
						const naissance = new Date(h.dateNaissance)
						year = naissance.getFullYear()
					}
                	return {
	                    horse_id: h.horse_id, 
	                    horse_nom: h.horse_nom, 
	                    horse_pere: h.pedigree.pere, 
	                    horse_mere: h.pedigree.mere, 
	                    horse_race: h.race_label, 
	                    horse_robe: h.robe_label, 
	                    horse_sexe: h.sexe_label, 
	                    horse_date: h.horse_datenaissance, 
	                    horse_sire: h.horse_sire, 
	                    horse_cle: h.horse_cle, 
	                    horse_wholesire: h.horse_sire+h.horse_cle,
	                    horse_year: year
	                }
                })

				this.licence_horses_load = false
			},

			async addHorseLicence(item) {
				this.processing = true
				const data = await this.addHorse(item.horse_wholesire, 'sire')
				if(data.new) {
					this.next(data.horse_id)
				}
				else {
					this.infoToast("toast.horse_already_exists")
					this.$router.push({ name: 'HorseFicheInfo', params: {horse_id: data.horse_id} })
				}
			}
		},

		computed: {
			error_message_trad() {
				if(['IK','IF','NOR'].indexOf(this.code_erreur) > -1) {
					return this.getTrad("horse.introuvable")
				}
				return this.getTrad("error."+(this.code_erreur == "" ? "UKE" : this.code_erreur))
			},
			race_formatted() {
				return this.filters_saved.race.map(race => race.race_label).join(' / ')
			}
		},

		components: {
			HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}

</script>